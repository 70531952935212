import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Menu,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Badge,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import { AuthContext } from "src/context/Auth";
import { MdOutlineShoppingCart } from "react-icons/md";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    color: "#E8E8E8",
    height: "41px",
    padding: "0px 12px",
    letterSpacing: "1px",
    marginLeft: "1px",
    marginRight: "1px",
    "&.active": {
      color: "#29B6ED",
      // borderBottom: "2px solid #29B6ED",
    },
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#E8E8E8",
      padding: "10px !important",
      height: "41px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&:active": {
      color: "#0dd0ff",
    },
    "&:hover": {
      color: "#0dd0ff",
    },
  },
  toolbar: {
    display: "flex",
    padding: "0px 0",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },

  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#061121",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "@media (max-width: 1220px)": {
      "& .MuiButtonBase-root": {
        // width: "100%",
        // minWidth: "100px",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
      },
    },
  },
  drawericon: {
    marginLeft: "0px",
  },
  logoImg: {
    width: "200px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "42px",
    },
  },
  menuMobile: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    // borderBottom: "1px solid #234e70",
    padding: "16px",
    "@media (max-width: 1220px)": {
      padding: "13px 0",
      width: "100%",
    },
  },

  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },

  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#fff",
    border: "1px solid #EB4D00",
    padding: "0 15px",
    background: "#EB4D00",
    borderRadius: "50px",
    height: "31px",
    "&:hover": {
      background: "fff",
      color: "#EB4D00",
    },
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },

  muiList: {
    "& .MuiPaper-root": {
      background: "none !important",
    },
    "& .MuiList-padding": {
      marginTop: "50px",
      paddingLeft: "0px",
    },
    "& .MuiMenu-list": {
      background: "#061121",
    },
    "& .MuiMenuItem-root": {
      padding: "8px 20px",
      "&:hover": {
        background: "linear-gradient(180deg, #51D2FC 0%, #0099DC 100%)",
      },
      "& a": {
        color: "#fff !important",
        fontSize: "14px",
        textDecoration: "none",
      },
    },
  },
}));

export default function Header() {
  const auth = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const handleClose12 = () => {
    setAnchorEl2(null);
  };

  const open11 = Boolean(anchorEl1);
  const open12 = Boolean(anchorEl2);
  const id1 = open11 ? "simple-popover" : undefined;
  const id2 = open12 ? "simple-popover" : undefined;
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const logoutHandler = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("tokenname");
    history.push("/login");
  };

  const {
    menuMobile,
    menuButton,
    menuButton1,
    toolbar,
    drawerContainer,
    drawericon,
    containerHeight,
    mainHeader,
    muiList,
  } = useStyles();
  const history = useHistory();
  const boughtData = auth?.cartData;
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);
  const displayDesktop = () => {
    return (
      <Box>
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            <Button
              {...{
                key: "Redeem",
                color: "inherit",
                to: "/aboutus",
                component: NavLink,
                className: menuButton,
              }}
            >
              About us
            </Button>
            <Button
              {...{
                key: "Redeem",
                color: "inherit",
                to: "/our-team",
                component: NavLink,
                className: menuButton,
              }}
            >
              Our Team
            </Button>
            <Button
              {...{
                key: "Redeem",
                color: "inherit",
                to: "/merchandise",
                component: NavLink,
                className: menuButton,
              }}
            >
              Merchandise
            </Button>
            <Button
              aria-describedby={id1}
              {...{
                key: "Developers",
                color: "inherit",
                to: "",
                className: menuButton,
              }}
              onClick={handleClick1}
            >
              Company <ExpandMoreIcon />
            </Button>
            {auth?.userData?.role === "ADMIN" ? (
              <></>
            ) : (
              <>
                <Button
                  {...{
                    key: "Redeem",
                    color: "inherit",
                    to: "/redeem-token",
                    component: NavLink,
                    className: menuButton,
                  }}
                >
                  Redeem
                </Button>
              </>
            )}

            {auth.isLogin && (
              <Button
                aria-describedby={id2}
                {...{
                  key: "Developers",
                  color: "inherit",
                  to: "",
                  className: menuButton,
                }}
                onClick={handleClick2}
              >
                Account <ExpandMoreIcon />
              </Button>
            )}

            {auth.isLogin && auth?.userData?.role !== "ADMIN" && (
              <>
                {boughtData && boughtData?.productCartDto?.length > 0 && (
                  <Button
                    {...{
                      key: "Redeem",
                      color: "inherit",
                      to: "/cart",
                      component: NavLink,
                      className: menuButton,
                    }}
                    startIcon={
                      <Badge
                        badgeContent={
                          boughtData && boughtData?.productCartDto?.length
                        }
                        color="error"
                      >
                        <MdOutlineShoppingCart />
                      </Badge>
                    }
                  >
                    <Box>Cart</Box>
                  </Button>
                )}
              </>
            )}

            {!auth.isLogin && (
              <Box style={{ marginLeft: "12px" }}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  {...{
                    key: "Dashboard",

                    to: "/login",
                    component: NavLink,
                  }}
                >
                  Login
                </Button>
              </Box>
            )}
          </Grid>
        </Toolbar>
      </Box>
    );
  };
  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img src="/images/logo_final.png" width="80px" alt="" />

            <Button
              {...{
                key: "Redeem",
                color: "inherit",
                to: "/aboutus",
                component: NavLink,
                className: menuButton,
              }}
            >
              About us
            </Button>
            <Button
              {...{
                key: "Redeem",
                color: "inherit",
                to: "/our-team",
                component: NavLink,
                className: menuButton,
              }}
            >
              Our Team
            </Button>

            <Button
              {...{
                key: "Redeem",
                color: "inherit",
                to: "/merchandise",
                component: NavLink,
                className: menuButton,
              }}
            >
              Merchandise
            </Button>
            <Button
              aria-describedby={id1}
              {...{
                key: "Developers",
                color: "inherit",
                to: "",
                className: menuButton,
              }}
              onClick={handleClick1}
            >
              Company <ExpandMoreIcon />
            </Button>
            {auth?.userData?.role !== "ADMIN" && (
              <Button
                {...{
                  key: "Redeem",
                  color: "inherit",
                  to: "/redeem-token",
                  component: NavLink,
                  className: menuButton,
                }}
              >
                Redeem
              </Button>
            )}

            {auth.isLogin && (
              <Button
                aria-describedby={id2}
                {...{
                  key: "Developers",
                  color: "inherit",
                  to: "",
                  className: menuButton,
                }}
                onClick={handleClick2}
              >
                Account <ExpandMoreIcon />
              </Button>
            )}

            {auth.isLogin &&
              auth?.userData?.role !== "ADMIN" &&
              boughtData &&
              boughtData?.productCartDto?.length > 0 && (
                <Button
                  {...{
                    key: "Redeem",
                    color: "inherit",
                    to: "/cart",
                    component: NavLink,
                    className: menuButton,
                  }}
                  startIcon={
                    <Badge
                      badgeContent={
                        boughtData && boughtData?.productCartDto?.length
                      }
                      color="error"
                    >
                      <MdOutlineShoppingCart />
                    </Badge>
                  }
                >
                  <Box>Cart</Box>
                </Button>
              )}

            {!auth.isLogin && (
              <Button
                className={menuButton1}
                style={{
                  marginLeft: "10px",
                  marginTop: "16px",
                  borderBottom: "none",
                  padding: "0px 46px",
                }}
                variant="contained"
                size="large"
                color="primary"
                {...{
                  key: "Login",

                  to: "/login",
                  component: NavLink,
                }}
              >
                <MenuItem className={menuMobile}>Login</MenuItem>
              </Button>
            )}
          </div>
        </Drawer>
        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2} align="right">
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#197ab3", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };
  const femmecubatorLogo = (
    <Box>
      <NavLink to="/">
        <Logo className="logoImg" />
      </NavLink>
    </Box>
  );

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "relative"}
        elevation={0}
        style={{
          backgroundColor: "transparent",
          border: "none",
          // paddingBottom: "7px",
          // paddingTop: "12px",
        }}
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "lg"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        className={muiList}
        open={Boolean(anchorEl)}
        onClose={handleClose1}
      >
        <MenuItem>
          <NavLink to="/help-planet">
            <img
              src="images/planet.png"
              alt="Help the planet"
              className="menu-icon"
            />
            Help the planet
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/carrers">
            <img src="images/career.png" alt="carrers" className="menu-icon" />
            Careers
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/where-buy">
            <img
              src="images/wheretobuy.png"
              alt="where buy"
              className="menu-icon"
            />
            Where to buy
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/return-bottle">
            <img
              src="images/bottle.png"
              alt="return bottle"
              className="menu-icon"
            />
            Bottle return
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/partner-with-us">
            <img
              src="images/partnerus.png"
              alt="partner"
              className="menu-icon"
            />
            Partner with us
          </NavLink>
        </MenuItem>
      </Menu>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl2}
        keepMounted
        className={muiList}
        open={Boolean(anchorEl2)}
        onClose={handleClose12}
      >
        <MenuItem>
          <NavLink to="/my-wallet">Wallet</NavLink>
        </MenuItem>

        <MenuItem>
          <NavLink to="/my-account">My account</NavLink>
        </MenuItem>
        {auth?.userData?.role === "ADMIN" ? (
          <></>
        ) : (
          <>
            {auth.isLogin && (
              <>
                <MenuItem>
                  <NavLink to="/profile-orders">Placed Order</NavLink>
                </MenuItem>
              </>
            )}
          </>
        )}
        {auth?.userData?.role === "ADMIN" && (
          <MenuItem>
            <NavLink to="/dashboard">Dashboard</NavLink>
          </MenuItem>
        )}
        <MenuItem
          style={{ fontSize: "14px", color: "#fff" }}
          onClick={() => setOpen(true)}
        >
          Logout
        </MenuItem>
      </Menu>

      {open && (
        <Dialog
          open={open}
          fullWidth
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
            <Box style={{ height: "90px" }}>
              <img src="images/logout.png" width="20%" alt="" />
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              align="center"
              style={{
                color: "#fff",
                fontSize: "18px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              <Typography variant="h6">
                Are you sure, you want to logout?
              </Typography>
            </DialogContentText>
            <Box display="flex" justifyContent="center" pb={2} pt={2}>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={logoutHandler}
                >
                  Yes
                </Button>
                &nbsp; &nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  No
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
