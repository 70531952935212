import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiIconButton: {
      root: {
        color: "#fff",
        padding: "0px",
        "&:hover": {
          color: "#51D2FC",
        },
      },
      edgeEnd: {
        marginRight: "0px",
      },
    },
    MuiInputLabel: {
      formControl: {
        top: "8px",
      },
    },

    // MuiInputLabel: {
    //   outlined: {
    //     "&.MuiInputLabel-marginDense": {
    //       transform: "translate(14px, 21px) scale(1)",
    //     },
    //   },
    // },
    MuiFormHelperText: {
      contained: {
        marginLeft: "0",
      },
    },
    MuiInput: {
      underline: {
        "&::after": {
          display: "none",
        },
        "&::before": {
          display: "none",
        },
      },
    },
    MuiAppBar: {
      positionFixed: {
        top: "0",
        left: "auto",
        right: "0",
        position: "fixed",
        background: "#0E203D",

        boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
        backdropFilter: "blur(42px)",
      },
    },
    MuiTableCell: {
      root: {
        display: "table-cell",
        fontSize: "13px",
        textAlign: "center",
        fontFamily: "'Poppins', sans-serif",
        fontWeight: "300",
        lineHeight: "1.43",
        paddingTop: "7px",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        paddingBottom: "7px",
        verticalAlign: "inherit",
      },
    },

    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },

    // MuiOutlinedInput: {
    //   colorSecondary: {
    //     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    //       color: "#222",
    //       borderColor: "#222",
    //     },
    //     "&.Mui-focused": {
    //       color: "#222",
    //     },
    //   },
    //   inputMarginDense: {
    //     paddingTop: "24.5px",
    //     paddingBottom: "24.5px",
    //   },
    // },

    MuiOutlinedInput: {
      adornedEnd: {
        paddingRight: "0px",
      },
      root: {
        borderRadius: "5px !important",
      },
      notchedOutline: {
        borderColor: "#fff",
        color: "#fff",
      },
      inputMarginDense: {
        paddingTop: "24.5px",
        paddingBottom: "24.5px",
      },

      adornedStart: {
        paddingLeft: "0px !important",
        "& svg": {
          fontSize: "18px",
          color: "#6B6B6B",
          marginBottom: "4px",
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#0E203C",
        color: "#fff",
      },
      outlined: {
        padding: "20px",
        width: "100%",
        border: "none",
      },
      rounded: {
        borderRadius: "7px",
      },
      elevation1: {
        background: "#fff",
        /* box-shadow: 0px 2px 1px -1px rgb(243 245 249), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%); */
      },
    },
    MuiPopover: {
      root: {
        zIndex: "1400 !important",
      },
    },
    MuiList: {
      padding: {
        paddingLeft: "8px",
      },
    },
    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
        color: "#1690F0",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#000" },
      },
    },
    MuiDropzoneArea: {
      text: {
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 200,
      },
      root: {
        width: "100%",
        maxWidth: "50%",
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "@media (max-width:767px)": {
          maxWidth: "100%",
          padding: "25px 10px 0px",
          minHeight: "auto",
        },
      },
      icon: {
        backgroundColor: "#f5f7fa",
        color: "#5a86ff",
        padding: "20px",
      },
    },

    MuiFormControl: {
      root: {
        border: "0",
        margin: "0",
        display: "inline-flex",
        padding: "0",
        position: "relative",
        minWidth: "0",
        flexDirection: "column",
        verticalAlign: "top",
        width: "100%",
      },
    },

    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiTableCell: {
      body: {
        color: "#fff",
        whiteSpace: "pre",
      },
      head: {
        color: "white",
        fontWeight: "500",
        lineHeight: "1.5rem",
        background: "linear-gradient(180deg, #51D2FC 0%, #0099DC 100%)",
        whiteSpace: "pre",
      },
      root: {
        fontSize: "13px",
        color: "#fff",
        paddingBottom: "7px",
        paddingTop: "7px",
        border: "1px solid rgb(224, 224, 224)",
        backgroundColor: "#061121",
      },
      stickyHeader: {
        backgroundColor: "none",
        color: "#9f9f9f",
        fontWeight: "300",
        fontSize: "14px",
      },
    },
    MuiDialog: {
      container: {
        height: "100%",
        outline: "0",
      },
      paperScrollPaper: {
        Width: "100%",
        maxWidth: "100%",
      },

      paperScrollPaper: {
        width: "100%",

        display: "flex",
        maxWidth: "100%",
        maxHeight: "calc(100% - 64px)",
        flexDirection: "column",
      },
      paper: {
        overflowY: "unset",
        backgroundColor: "#061121",
        // boxShadow: "#00c1ff 0px 7px 29px 0px",
      },
      paperWidthSm: {
        maxWidth: "400px !important",
        paddingTop: "20px",
        paddingBottom: "20px",
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "19px",
        color: "#888181 !important",
        // border: "1px solid #fff",
      },
      input: {
        fontSize: 14,
        color: "#888181 !important",
        height: "0.1876em",
        "&:-webkit-autofill": {
          "-webkit-background-clip": "text !important",
          // transitionDelay: "9999s",
          "caret-color": "transparent",
          "-webkit-box-shadow": "0 0 0 100px transparent inset",
          "-webkit-text-fill-color": "#888181",
        },
        "&:-internal-autofill-selected": {
          color: "#888181",
        },
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: "#fff",
      },
      iconButton: {
        backgroundColor: "#137faa",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#30a8ce !important",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgb(0 0 0 / 26%)",
      },
    },
    MuiButton: {
      textPrimary: {
        "&:hover": {
          color: "#fff",
          backgroundColor: "#000",
        },
      },
      root: {
        color: "#300760",
        minWidth: "64px",
        //padding: '6px 60px',
        borderRadius: "none",
        // backgroundColor: "#1690F0",
      },
      text: {
        padding: "6px 8px ",
      },
      label: {
        fontSize: "14px",
        fontWeight: "400",
      },
      containedSecondary: {
        backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        border: "1px solid #43c8f69c",
        backdropFilter: "blur(7.5px)",
        borderRadius: "100px",
        color: "#fff",
        fontSize: "15px",
        height: "48px",
        whiteSpace: "pre",
        "&:hover": {
          background:
            "linear-gradient(180deg, #51D2FC 0%, #0099DC 100%) !important",
        },
      },

      containedPrimary: {
        background: "linear-gradient(180deg, #51D2FC 0%, #0099DC 100%)",
        borderRadius: "100px",
        color: "#fff",
        fontSize: "15px",
        border: "1px solid #43c8f69c",
        padding: "8px 30px 6px",
        whiteSpace: "pre",
        // borderRadius: "12px",
        "&:hover": {
          background: "rgba(255, 255, 255, 0.1) !important",
          border: "1px solid #43c8f69c",
          backdropFilter: "blur(7.5px)",
        },
      },
      outlinedPrimary: {
        color: "rgb(90 134 255)",
        fontWeight: 600,
        height: "50px",
        minWidth: "100px",
        border: "1px solid rgb(90 134 255)",
        "&:hover": {
          backgroundColor: "rgb(90 134 255)",
          border: "1px solid rgb(90 134 255)",
          color: "#fff",
        },
      },
      outlinedSecondary: {
        border: "1px solid #616161",
        borderRadius: "100px",
        color: "rgba(255, 255, 255, 0.6)",
        padding: "8px 30px 6px",
        "&:hover": {
          border: "1px solid #0099DC",
          color: "#0099DC",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
      containedSizeSmall: {
        padding: "6px 23px",
        fontSize: "14px",
        fontWeight: "300",
      },
      containedSizeLarge: {
        padding: "9px 46px",
        fontSize: "14px",
        fontWeight: "300",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },

    MuiTypography: {
      body1: {
        fontSize: "15px",
        fontFamily: "'Poppins', sans-serif",
        fontWeight: "300",
        lineHeight: "1.5",
        "@media (max-width:767px)": {
          fontSize: "12px",
        },
      },
      subtitle1: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },
    MuiInputAdornment: {
      positionStart: {
        paddingLeft: "14px",
        marginRight: "5px",
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
