// const url = process.env.REACT_APP_BASE_URL;
// const url = "http://172.16.1.220:7067"; //Java
// const url = "http://172.16.1.220:7067"; //Java---
// const url = "https://java.cryptowater.com"; //Jav
const url = "https://java-dropcoin.mobiloitte.org"; //Java

const user = `${url}/account`;
const auth = `${url}/auth`;
const account = `${url}/account`;
const admin = `${url}/account/admin/user-management`;
const wallet = `${url}/wallet`;
const staticData = `${url}/static`;
const dashbaordData = `${url}/account/admin/dashboard`;
const adminnn = `${url}/wallet`;
const orderData = `${url}/merchandise`;
const merchandise = `${url}/merchandise/admin`;
const product = `${url}/merchandise/product`;
const ApiConfig = {
  userLogin: `${auth}`,
  userSignUp: `${user}/signup`,
  verifyUser: `${user}/verify-user`,
  myAccount: `${account}/my-account`,
  resendverifyemail: `${account}/resend-verify-email`,
  profileUpdate: `${user}/profile-update`,
  uploadFile: `${user}/upload-file`,
  filteruserdetails: `${admin}/filter-user-details`,
  withdraw: `${wallet}/wallet/withdraw`,
  WithdrawApprove: `${wallet}/wallet/approve-withdraw`,
  AddressWallet: `${wallet}/wallet/get-address`,
  getrandomIddetails: `${wallet}/wallet/get-randomId-details`,
  getalluserbalanceandcoinlist: `${wallet}/wallet/get-all-user-balance-and-coinlist`,
  getdeposits: `${wallet}/wallet/get-deposits`,
  DepositHistoryTransaction: `${wallet}/get-all-transaction-history`,
  randocodegnerate: `${wallet}/wallet/random-id-generate`,
  WithdrawApprove: `${wallet}/wallet/approve-withdraw`,
  addradomIdbalance: `${wallet}/wallet/add-radomId-balance`,
  getStatusdetails: `${wallet}/wallet/get-Status-details`,
  getredeemdetails: `${wallet}/wallet/get-redeem-details`,
  forgotPassword: `${user}/forget-password/mobiloitApp`,
  resetPasswordmobile: `${account}/reset-password-mobile-app`,
  verifyOtpForget: `${user}/verify-sms-code-mobile-app`,

  DeleteUserDetails: `${admin}/delete-user-detail`,
  ViewUserDetails: `${admin}/user-details`,
  dashBoardApi: `${dashbaordData}/totalUserCount`,
  staticContentList: `${staticData}/get-all-static-content-data`,
  faqList: `${staticData}/get-faq-list`,
  addFaq: `${staticData}/add-new-faq`,
  viewFaq: `${staticData}/get-faq-detail`,
  updateFaq: `${staticData}/update-faq`,
  deleteFaq: `${staticData}/delete-faq`,
  viewStaticContent: `${staticData}/get-static-page-data-by-page-key`,
  staticContent: `${staticData}/get-static-page-data`,
  UpdatestaticContent: `${staticData}/update-static-content-data`,
  activeUser: `${admin}/Activate-Account`,
  BlockAccount: `${admin}/Block-Account`,
  reedemCount: `${wallet}/wallet/get-status-details-by-userid`,
  earnedCount: `${wallet}/wallet/get-Earnd-details`,
  csvfiledownload: `${wallet}/wallet/csv-file-download`,
  getuseraddress: `${wallet}/wallet/get-user-address`,
  addnodeaddress: `${wallet}/wallet/add-node-address`,
  subscribeNow: `${staticData}/post-news-letter-to-user`,
  submitPartner: `${staticData}/submit-partner`,
  SubmitContactUs: `${staticData}/submit-contact-us`,
  adminGetWalletAdd: `${adminnn}/admin/hot-cold-storage/get-storage-details-with-latestTime`,
  adminBalanceUpd: `${adminnn}/admin/hot-cold-storage/update-storage-wallet-balance`,
  uploadFileImg: `${account}/upload-file`,
  AddProduct: `${account}/add-product`,
  TeamList: `${staticData}/get-static-page-data`,

  AddProduct: `${merchandise}/add-product`,
  ListAllProduct: `${merchandise}/get-product-list`,
  blockUnblockProduct: `${merchandise}/block-unblock-product`,
  deleteproduct: `${merchandise}/delete-product`,
  viewSingleProduct: `${product}/view-product`,

  multiUploadFile: `${account}/upload-mutliple-file`,
  // ----------
  getorderlist: `${orderData}/order/get-order-list`,
  vieworder: `${orderData}/order/view-order`,
  vieworderbyorderId: `${orderData}/order/view-order-by-orderId`,
  cartplaceorder: `${orderData}/order/cart-place-order`,
  createPayment: `${url}/merchandise/create-payment`,

  productlist: `${orderData}/product/product-list`,
  viewproduct: `${orderData}/product/view-product`,
  addcartproduct: `${orderData}/cart/add-cart-product`,
  adddeliveryaddress: `${orderData}/order/add-delivery-address`,
  changeorderstatus: `${orderData}/order/admin/change-order-status`,
  getdeliverylist: `${orderData}/order/get-delivery-list`,
  editdeliveryaddress: `${orderData}/order/edit-delivery-address`,
  viewcart: `${orderData}/cart/view-cart`,
  removedeliveryaddres: `${orderData}/order/remove-delivery-address`,
  deletecartproduct: `${orderData}/cart/delete-cart-product-by-id`,
  editcartproduct: `${orderData}/cart/edit-cart-product`,

  /////Career///
  getallcareer: `${staticData}/get-all-career`,
  addbottlelocation: `${staticData}/add-bottle-location`,
  updatebottlelocation: `${staticData}/update-bottle-location`,
  getlocationlist: `${staticData}/admin/get-location-list`,
  getlocationlistuser: `${staticData}/get-location-list`,
  deletelocation: `${staticData}/delete-location`,
  addcareer: `${staticData}/add-career`,
  deletecareers: `${staticData}/delete-careers-by-teamId`,
  careerdetailsupdate: `${staticData}/career-details-update`,
  blockunBlockcareer: `${staticData}/block-or-unBlock-career`,
  getdetailsteamId: `${staticData}/get-details-by-teamId`,
  // admineditproductdata: `${url}/merchandise/admin/edit-product-data`,
  // updateImage: `${url}/merchandise/admin/edit-product-image`,
  selectAdress: `${url}/merchandise/order/select-unselect-delivery-address`,

  viewallOrderbyUserid: `${url}/merchandise/order/view-allOrder-by-Userid`,
  placeOrder: `${url}/merchandise/order/place-order`,
  updateProduct: `${merchandise}/update-product-data`,
};

export default ApiConfig;
