export default {
  h1: {
    fontWeight: 600,
    fontSize: 80,
    fontFamily: "'Acumin Pro', sans-serif",
    lineHeight: "50px",
    "@media(max-width:768px)": {
      fontSize: "30px !important",
      lineHeight: "35px",
    },
  },
  h2: {
    fontWeight: 600,
    fontSize: 50,
    fontFamily: "'Acumin Pro', sans-serif",
    lineHeight: "50px",
    "@media(max-width:767px)": {
      fontSize: "30px !important",
      lineHeight: "45px",
    },
  },
  h3: {
    fontWeight: 500,
    fontSize: 25,
    fontFamily: "'Acumin Pro', sans-serif",
    lineHeight: "40px",
    "@media(max-width:767px)": {
      fontSize: "20px !important",
      lineHeight: "30px",
    },
  },
  h4: {
    fontWeight: 600,
    fontSize: 20,
    fontFamily: "'Acumin Pro', sans-serif",
    lineHeight: "30px",
    "@media(max-width:767px)": {
      fontSize: "18px !important",
      lineHeight: "25px",
    },
  },
  h5: {
    fontWeight: 400,
    fontSize: 20,
    fontFamily: "'Acumin Pro', sans-serif",
    lineHeight: "32px",
    "@media(max-width:767px)": {
      fontSize: "16px !important",
    },
  },
  h6: {
    fontWeight: 400,
    fontSize: 18,
    fontFamily: "'Acumin Pro', sans-serif",
    lineHeight: "20px",
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
    fontFamily: "'Acumin Pro', sans-serif",
    fontWeight: "300",
  },
  body1: {
    fontSize: 12,
    fontFamily: "'Acumin Pro', sans-serif",
  },
  body2: {
    fontSize: 14,
    fontWeight: "600",

    fontFamily: "'Acumin Pro', sans-serif",
    lineHeight: "21px",
  },
};
