import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    // guard:true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },

  {
    exact: true,
    path: "/aboutus",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticsContent/Aboutus")),
  },
  {
    exact: true,
    path: "/help-planet",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticsContent/HelpPlanet")),
  },
  {
    exact: true,
    path: "/our-team",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticsContent/Ourteam")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    guard: true,
    path: "/dashboard",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  {
    exact: true,
    guard: true,
    path: "/users",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Userlist")),
  },
  // -------- Admin Product Management -----
  {
    exact: true,
    guard: true,
    path: "/product-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ProductManagement/index")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/team-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/TeamManagement/TeamManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-team",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/TeamManagement/AddTeam")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-address",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AddressManagement/AddAddress")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-address",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AddressManagement/ViewAddress")
    ),
  },
 
  {
    exact: true,
    guard: true,
    path: "/list-address",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AddressManagement/AddressList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/addbottle-address",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AddressManagement/AddBottleAddress")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/viewbottle-address",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AddressManagement/ViewBottleAddress")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/list-address",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AddressManagement/AddressList")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/bottle-address",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/AddressManagement/BottleReturnList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-team",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/TeamManagement/ViewTeam")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-product",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ProductManagement/AddProduct")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-product",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ProductManagement/ViewProduct")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/order-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/OrderManagement")),
  },
  {
    exact: true,
    guard: true,
    // path: "/cancel-order",
    path: "/cancel-order/:id1/:id2",
    layout: HomeLayout,
    component: lazy(() => import("src/component/CancelOrder")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-order-admin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/OrderManagement/ViewOrder")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-product",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ProductManagement/AddProduct")
    ),
  },
  // {
  //   exact: true,
  //   path: "/view-product",
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/ProductManagement/AddProduct")
  //   ),
  // },

  {
    exact: true,
    guard: true,
    path: "/redeem",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/RedeemList")),
  },

  {
    exact: true,
    path: "/redeem-token",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/RedeemTok/RedeemToken")),
  },

  {
    exact: true,
    guard: true,
    path: "/user-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/User/UserManagement")
    ),
  },

  {
    exact: true,
    path: "/view-profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Kycpending/Seeprofile")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/signup/VerifyOtp")),
  },
  {
    exact: true,
    path: "/logo",
    layout: LoginLayout,
    component: lazy(() => import("src/component/Logo")),
  },
  {
    exact: true,
    path: "/signup",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/signup/signup")),
  },
  {
    exact: true,
    path: "/forget-password",
    // guard:true,
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    // guard: true,
    path: "/coin-approve",
    // guard:true,
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/MyWallet/CoinApprove")),
  },

  {
    exact: true,
    path: "/instrauctions",
    // guard:true,
    component: lazy(() => import("src/views/auth/forget-password-link/index")),
  },
  {
    exact: true,
    path: "/reset-password",
    // guard:true,
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },

  {
    exact: true,
    path: "/white-paper",
    // guard:true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/WhitePaper")),
  },

  {
    exact: true,
    path: "/static-management",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/Static")
    ),
  },

  {
    exact: true,
    path: "/add-statics",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/AddStatics")
    ),
  },

  {
    exact: true,
    path: "/social-links",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/SocialLinks")
    ),
  },

  {
    exact: true,
    path: "/edit-links",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/SocialLinksEdit")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/edit-content",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/AboutEdit")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/view-terms",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/TermsCondition")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/view-step",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/AddFollowStep")
    ),
  },
  // {
  //   exact: true,
  //   path: "/view-terms",
  //   // guard:true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/StaticManagement/TermsCondition")
  //   ),
  // },

  {
    exact: true,
    guard: true,
    path: "/edit-terms",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/TermsConditionEdit")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/faq",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/Faq")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-faq",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/EditFaq")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/add-faq",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/AddFaq")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/policy",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/PrivacyPolicy")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/edit-policy",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/PrivacyPolicyEdit")
    ),
  },

  {
    exact: true,
    path: "/terms-condition",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/TermsAndConditions")),
  },
  {
    exact: true,
    path: "/cookies-policy",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/TermsAndConditions/CookiesPolicy")
    ),
  },
  {
    exact: true,
    path: "/product-detalis",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Product/Product")),
  },

  {
    exact: true,
    guard: true,
    path: "/cart",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MyCart/Index")),
  },
  {
    exact: true,
    guard: true,
    path: "/address",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MyCart/Address")),
  },
  {
    exact: true,
    guard: true,
    path: "/payment",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MyCart/Payment")),
  },
  {
    exact: true,
    guard: true,
    path: "/profile-orders",
    // path: "/profile-orders/pay/cancel?token=EC-8FT524187L150784U",
    // path: "/profile-orders/:id1/:id2",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MyCart/OrderProfile")),
  },
  {
    exact: true,
    path: "/faqs",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Faq's/Faq's")),
  },
  {
    exact: true,
    path: "/terms-services",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/TermsAndConditions/TermsServices")
    ),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Privacy")),
  },
  {
    exact: true,
    path: "/cookies",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Cookies")),
  },
  {
    exact: true,
    path: "/my-address",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Mine/Mineaddress")),
  },
  {
    exact: true,
    guard: true,
    path: "/my-account",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MyAccount/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/my-wallet",

    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MyWallet/index")),
  },
  {
    exact: true,
    path: "/carrers",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticsContent/Carrers")),
  },
  {
    exact: true,
    path: "/contactus",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticsContent/ContactUs")),
  },
  {
    exact: true,
    path: "/merchandise",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticsContent/Merchandize")),
  },
  {
    exact: true,
    path: "/return-bottle",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticsContent/ReturnBottle")
    ), 
  },
  {
    exact: true,
    path: "/dropcoin",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticsContent/DropCoin")),
  },
  {
    exact: true,
    path: "/partner-with-us",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticsContent/PartnerWithUs")
    ),
  },
  {
    exact: true,
    path: "/where-buy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticsContent/WhereBuy")),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];
