import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import ApiConfig from "src/config/APICongig";
import axios from "axios";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  btn: {
    minWidth: "154px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "100px",
    },
  },
}));

export default function CookieModal({ open, setOpen }) {
  const classes = useStyles();
  const [cookiesData, setCookiesData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const getStaticContentHandler = async () => {
    try {
      setIsLoading(true);

      const res = await axios({
        method: "GET",
        url: ApiConfig.staticContent,
        params: {
          pageKey: "Cookies",
        },
      });
      if (res.data.status === 200) {
        setCookiesData(res.data.data);
        setIsLoading(false);
      } else {
        toast.warn(res.data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        toast.error(error.response.data.responseMessage);
      } else {
        toast.error(error.message);
      }
    }
  };
  useEffect(() => {
    getStaticContentHandler();
  }, []);
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="md"
      className="scrollDialog"
    >
      <DialogContent>
        <Box onClick={() => setOpen(false)}>
          <IconButton style={{ position: "absolute", top: 10, right: 10 }}>
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </Box>

        <Box>
          <img src="/images/logo_final.png" />

          <Divider style={{ background: "rgb(2, 20, 46)" }} />
          <Box my={2}>
            <Typography variant="h4" style={{ color: "#fff" }}>
              Privacy Preference Center
            </Typography>
          </Box>
          <Box mb={4}>
            <Typography
              variant="body1"
              style={{ color: "#fff", wordBreak: "break-all" }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: cookiesData?.pageData,
                }}
              />
            </Typography>
          </Box>
          {/* <Box mb={4}>
            <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              style={{}}
            >
              Allow
            </Button>

            <Button
              variant="contained"
              className={classes.btn}
              color="secondary"
              onClick={() => setOpen(false)}
              style={{ marginLeft: "20px" }}
            >
              Deny
            </Button>
          </Box> */}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
