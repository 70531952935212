import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import ApiConfig from "src/config/APICongig";
import { toast } from "react-toastify";
import { SUPPORTED_WALLETS } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import { calculateTimeLeft } from "src/utils";
const SESSION_TIMEOUT = 24 * 60 * 60 * 1000;

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);
    // axios.defaults.headers.common.Authorization = `${accessToken}`;
  } else {
    localStorage.removeItem("token");
    // delete axios.defaults.headers.common.Authorization;
  }
};

const setWalletAddressSession = (userAddress) => {
  if (userAddress) {
    localStorage.setItem("userAddress", userAddress);
  } else {
    localStorage.removeItem("userAddress");
  }
};

function checkLogin() {
  const accessToken = localStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const { activate, chainId, library, account, deactivate } = useWeb3React();
  const [staticsData, setstaticsData] = useState("");
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState();
  const [userLogo, setUserLogo] = useState();
  const [useBanner, setUserBanner] = useState();
  const [endTime, setEndtime] = useState();
  const [cartData, setCartData] = useState([]);
  const [cartLoading, setCartLoading] = useState(true);
  const [timeLeft, setTimeLeft] = useState();
  useEffect(() => {
    const logoutAfterTimeout = () => {
      setIsLogin(false);
      setSession(null);
      deactivate();
    };

    if (isLogin) {
      const lastActiveTime = localStorage.getItem("lastActiveTime");
      const currentTime = new Date().getTime();

      if (lastActiveTime) {
        const timeSinceLastActive = currentTime - parseInt(lastActiveTime, 10);

        if (timeSinceLastActive >= SESSION_TIMEOUT) {
          logoutAfterTimeout();
        }
      }

      localStorage.setItem("lastActiveTime", currentTime.toString());
    }

    if (!isLogin) {
      localStorage.removeItem("lastActiveTime");
    }
  }, [isLogin]);
  const getProfileHandler = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.myAccount,
        headers: {
          authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      });
      if (res.data.status === 200) {
        setUserData(res.data.data);
        staticContentListHandler();
      }
    } catch (error) {
      console.log("000000", error);
      if (error.response) {
        if (error.response.data.status === 400) {
          localStorage.removeItem("token");
        }
      }
    }
  };
  const getBannerHandler = async () => {
    try {
      const res = await axios.get(ApiConfig.getBanner, {
        // headers: {
        //   token: localStorage.getItem("token"),
        // },
      });
      if (res.data.status === 200) {
        setUserBanner(res.data.result);
      } else {
        setIsLogin(false);
      }
    } catch (error) {
      setIsLogin(false);
    }
  };
  const getLogoHandler = async () => {
    try {
      const res = await axios.get(ApiConfig.getLogo, {
        // headers: {
        //   token: localStorage.getItem("token"),
        // },
      });
      if (res.data.statusCode === 200) {
        setUserLogo(res.data.result);
      } else {
        setIsLogin(false);
      }
    } catch (error) {
      setIsLogin(false);
    }
  };

  const boughtHandler = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.viewcart,
        headers: {
          authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      });
      if (res) {
        const filteredCartData = res.data.data.productCartDto.filter(
          (item) => item?.productImages?.length > 0
        );
        res.data.data.productCartDto = filteredCartData;
        setCartData(res?.data?.data);
        setCartLoading(false);
      } else {
        setCartLoading(false);
        setCartData([]);
      }
    } catch (error) {
      setCartLoading(false);
      setCartData([]);
    }
  };
  useEffect(() => {
    if (window.localStorage.getItem("token")) {
      getProfileHandler();
      boughtHandler();
    }
  }, [window.localStorage.getItem("token")]);

  const staticContentListHandler = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.staticContentList,
        headers: {
          authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      });
      if (res.data.status === 200) {
        setstaticsData(res.data.data);
      } else {
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  // useEffect(() => {
  //   staticContentListHandler();
  // }, []);

  const connectWalletHandler = (data) => {
    try {
      const connector = data.connector;

      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }

      activate(connector, undefined, true).catch((error) => {
        if (error) {
          window.localStorage.removeItem("walletName");
          toast.error(JSON.stringify(error.message));
          window.localStorage.removeItem("walletName");
          activate(connector);
        }
      });
    } catch (error) {
      toast.error(JSON.stringify(error.message));
    }
  };

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  useEffect(() => {
    if (window.localStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS?.filter(
        (data) => data.name === window.localStorage.getItem("walletName")
      );
      console.log("selectectWalletDetails++++", selectectWalletDetails);
      // connectWalletHandler(selectectWalletDetails[0].data);
    }
  }, []);

  let data = {
    isLogin,
    userData,
    staticsData,
    userLogo,
    timeLeft,
    setEndtime,
    setTimeLeft,
    useBanner,
    cartData,
    cartLoading,
    setCartLoading: setCartLoading,
    boughtHandler: boughtHandler,
    getProfileHandler: () => getProfileHandler(),
    updateUser: (account) => {
      setWalletAddressSession(account);
    },
    connectWallet: (data) => connectWalletHandler(data),
    disConnectWallet: (data) => {
      deactivate();
      window.localStorage.removeItem("walletName");
    },
    userLogIn: (type, data) => {
      setIsLogin(type);
      setSession(data);
    },
    checkLogin: () => checkLogin(),
    userLogout: (type, data, userData) => {
      setIsLogin(type);
      setSession(data);
      setUserData(userData);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
